import React, { useEffect, useRef, useState } from 'react'
import "./UserViewPage.css"
import Header from '../../CommonComponet/Header'
import Footer from '../../CommonComponet/Footer'
import Favourites2 from "../../../images/UserDetailspage/Star2.png";
import Calendar from "../../../images/UserDetailspage/Calendar.png";
import GenderMale from "../../../images/UserDetailspage/GenderMale.png"
import editIcon from '../../../images/UserDetailspage/Vector (1).png';
import verifyIcon from '../../../images/verified-icon.png';
import { Link, useNavigate } from 'react-router-dom';
import { CountrycodeApi, EmailMobileUpdateApi, EmailVerifyApi, MyProfileData, UserAllGallery, UserDetailsUpdateApi, WithdrawalRequest, verifyOtpEmailnumberApi } from '../../../api/Api';
import privateImg from "../../../images/privare_image.png";
import privateImglock from "../../../images/lock.png"
import { useAuth } from '../../../middleware/AuthProvider';
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DataLoader from '../../../components/ScreenLoader/DataLoader';
import FooterMobile from '../../CommonComponet/FooterMobile';
import { FaPlay, FaPlus } from 'react-icons/fa6';
import { Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import ReactInputVerificationCode from "react-input-verification-code";
import { Helmet } from 'react-helmet';
import { MdShare } from "react-icons/md";
import { MdOutlineContentCopy } from "react-icons/md";
import qrcodeimg from '../../../images/qrcode.png';
import { IoMdDownload } from "react-icons/io";
import { IoClose, IoPencil } from 'react-icons/io5';
import { HiOutlinePencil } from 'react-icons/hi2';

const UserViewPage = () => {
  const navigate = useNavigate();
  const { AuthData, siteSetting, setAuthData } = useAuth()
  const [isDataLoader, setisDataLoader] = useState(false);
  const [publicGallery, setPublicGallery] = useState([]);

  const [userData, setUserData] = useState([]);
  const [number, setNumber] = useState("")
  const [email, setEmail] = useState("")
  const [countryData, setCountryData] = useState([])
  const [countryId, setCountryId] = useState("")
  const [verificationCode, setVerificationCode] = useState("");
  const [countrcode, setCountrcode] = useState("")

  const [skeletonUserImage, setSkeletonUserImage] = useState(true);
  const [loginwith, setLoginwith] = useState("")
  const [emailNumberShowModal, setEmailNumberShowModal] = useState(false)
  const [showhideotpbtn, setShowhideotpbtn] = useState(false)
  const [emailnumberhideshow, setEmailnumberhideshow] = useState(true)
  const [numberError, setNumberError] = useState('');
  const [emailError, setEmailError] = useState("")
  const [unableModalShare, setunableModalShare] = useState(false);


  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    document.title = "My Profile On Hukup Australia"
  }, [])

  const handleshowunablehidemodal = () => {
    setunableModalShare(false)
  }

  useEffect(() => {
    MyProfileApi();
    UserPublicGalleryApi()
    CountrycodeApiCall()
  }, []);

  const MyProfileApi = () => {
    MyProfileData()
      .then(function (response) {
        setUserData(response.data.data)
        setSkeletonUserImage(false)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // const VerifyUserEmail = () => {
  //   setisDataLoader(true);
  //   EmailVerifyApi()
  //     .then(function (response) {
  //       setisDataLoader(false)
  //       if (response.data.status == true) {
  //         Store.addNotification({
  //           message: response.data.message,
  //           type: "success",
  //           insert: "top",
  //           container: "top-right",
  //           animationIn: ["animate__animated", "animate__zoomIn"],
  //           animationOut: ["animate__animated", "animate__fadeOut"],
  //           dismiss: {
  //             duration: 2000,
  //             onScreen: false
  //           }
  //         });
  //       }
  //       else {
  //         Store.addNotification({
  //           message: response.data.message,
  //           type: "danger",
  //           insert: "top",
  //           container: "top-right",
  //           animationIn: ["animate__animated", "animate__zoomIn"],
  //           animationOut: ["animate__animated", "animate__fadeOut"],
  //           dismiss: {
  //             duration: 2000,
  //             onScreen: false
  //           }
  //         });
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       setisDataLoader(false)
  //     });
  // }

  const CountrycodeApiCall = () => {
    CountrycodeApi().then(function (response) {
      setCountryData(response.data.data)
      setCountryId(response.data.data[0].id)
    })
      .catch(function (error) {
        console.log(error);
      })
  }

  const handleCountryId = (e) => {
    if (e && e.target) {
      var Iddata = e.target.value;
      if (countryId != Iddata) {
        setNumber("");
        setCountryId(Iddata);
      } else {
        setCountryId(Iddata);
      }
    }
  }

  const handleMobileNumberChange = (e) => {
    const inputNumber = e.target.value;
    // Remove any non-numeric characters
    const numericValue = inputNumber.replace(/\D/g, '');
    var numbervalidatine = countryData.find((ele) => ele.id == countryId)
    setCountrcode(numbervalidatine.country_code)
    // Ensure the numeric value is exactly 10 digits
    if (numericValue.length <= numbervalidatine.number_validation) {
      setNumber(numericValue);
    }
  };

  //  -- validatione for number -----
  const validationnumber = () => {
    setNumberError("");
    var isvalid = true
    if (number == "") {
      setNumberError("Please Enter Mobile Number");
      isvalid = false;
    }
    else {
      var numbervalidatine = countryData.find((ele) => ele.id == countryId);
      const mobileNumberPattern = new RegExp(`^[0-9]{${numbervalidatine.number_validation}}$`);
      if (!mobileNumberPattern.test(number)) {
        setNumberError(`Please Enter a ${numbervalidatine.number_validation}-digit Mobile Number`);
        isvalid = false;
      }

    }

    return isvalid;
  }
  //  -- validatione for email -----
  const validationemail = () => {
    setEmailError("");
    var isvalid = true
    if (email.trim() != "") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email.trim())) {
        setEmailError("Email is not valid")
        isvalid = false;
      }
    } else {
      setEmailError("Please Enter Email")
      isvalid = false;

    }
    return isvalid;
  }

  // --- send otp to user email or number ------
  const sendOtpUserEmailnumber = () => {

    var data = {
      type: loginwith,
    }
    if (loginwith == "mobile") {
      if (!validationnumber()) {
        return false;
      }
      data = {
        ...data,
        mobile: number,
        country_id: countryId,
      }
    }

    if (loginwith == "email") {
      if (!validationemail()) {
        return false;
      }
      data = {
        ...data,
        email: email,
      }
    }

    EmailMobileUpdateApi(data).then(function (response) {
      if (response.data.status) {
        setMinutes(1);
        setShowhideotpbtn(true);
        setEmailnumberhideshow(false);
        Store.addNotification({
          message: response.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
      else {
        Store.addNotification({
          message: response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
    })
      .catch(function (error) {
        console.log(error);
        Store.addNotification({
          message: "Something goes wrong, please try again later!",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      });
  }
  //  --- verify otp to user -------
  const verifyOtpEmailnumber = () => {

    if (verificationCode.indexOf("·") != -1) {
      return false;
    }

    var data = {
      type: loginwith,
    }
    if (loginwith == "mobile") {
      data = {
        ...data,
        mobile: number,
        country_id: countryId,
        otp: verificationCode
      }
    }

    if (loginwith == "email") {
      data = {
        ...data,
        email: email,
        otp: verificationCode
      }
    }


    verifyOtpEmailnumberApi(data).then(function (response) {
      if (response.data.status) {
        setEmailNumberShowModal(false)
        MyProfileApi()
        Store.addNotification({
          message: response.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
      else {
        Store.addNotification({
          message: response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
    })
      .catch(function (error) {
        console.log(error);
        Store.addNotification({
          message: "Something goes wrong, please try again later!",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      });
  }

  const closeToModal = () => {
    setEmailNumberShowModal(false);
    setNumber("");
    setEmail("");
    setVerificationCode("");
    setNumberError("")
    setEmailError("")
    setMinutes(0);
    setSeconds(0);
    setShowhideotpbtn(false);
    setEmailnumberhideshow(true);
    CountrycodeApiCall()
  }

  const UserPublicGalleryApi = () => {
    UserAllGallery()
      .then(function (response) {
        setPublicGallery(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const copyTorefrrallink = (urlToCopy) => {
    navigator.clipboard
      .writeText(urlToCopy)
    Store.addNotification({
      message: "Referral Code Copied",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__zoomIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 2000,
        onScreen: false,
      },
    });
  };

  const copyToClipboard = (urlToCopy) => {
    handleShareClick(urlToCopy);
    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => {
      })
      .catch((error) => {
      });
  };

  const handleShareClick = async (urlToCopy) => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: urlToCopy,
          text: "Share Referral Link",
          url: urlToCopy,
        });
      } else {
        console.error("Web Share API is not supported in this browser.");
        setunableModalShare(true);
      }
    } catch (error) {
      console.error("Error sharing:", error.message);
    }
  };


  const handleShareClickimg = async (imageSrc) => {
    try {
      const imageData = await fetch(imageSrc).then(res => res.blob());
      const file = new File([imageData], "some.png", { type: "image/png" });
      if (navigator.canShare && navigator.canShare({ files: [file] })) {
        await navigator.share({
          title: "Share File",
          files: [file]
        });
        console.log("Image shared successfully");
      } else {
        console.error("Web Share API is not supported in this browser.");
        setunableModalShare(true);
      }
    } catch (err) {
      console.error("Share failed:", err.message);
    }

    // try {
    //   if (navigator.share) {
    //     await navigator.share({
    //       title: 'Share Image',
    //       text: 'Check out this image!',
    //       url: imageSrc,
    //     });
    //     console.log('Image shared successfully');
    //   } else {
    //     throw new Error('Web Share API not supported.');
    //   }
    // } catch (error) {
    //   console.error('Error sharing image:', error.message);
    // }
  };

  const handleButtonClick = (imageUrl) => {
    handleShareClickimg(imageUrl);
  };

  const paymentoutrefrerral = () => {
    if (userData?.credits < siteSetting?.siteSetting?.minimum_credits) {
      Store.addNotification({
        message: "Insufficient credits",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__zoomIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: false,
        },
      });
      return false;
    }
    WithdrawalRequest()
      .then(function (response) {
        if (!response.data.status) {
          Store.addNotification({
            message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false;
        }
        Store.addNotification({
          message: response.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false,
          },
        });
      })
      .catch(function (error) {
        Store.addNotification({
          message: error.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false,
          },
        });
      });
  }

  const download = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const element = document.createElement("a");
      element.href = url;
      element.download = "image.png";
      document.body.appendChild(element); // Required for this to work in Firefox
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      console.error("Error downloading the image:", error);
    }
  };
  const [profileImg, setProfileImg] = useState(null)
  const fileInputRef = useRef(null);

  // Function to handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      userUpdateData(file)
      setProfileImg(file); // Update state with selected image
    }
  };
  const formatDate = (dateString) => {
    // Parse the input date string into a Date object
    const date = new Date(dateString);

    // Get the year, month, and day
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based
    const day = date.getDate().toString().padStart(2, '0');

    // Format the date as "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  };
  // console.log(formatDate(AuthData.date_of_birth));
  const userUpdateData = (file) => {
    // setisDataLoader(true)

    const formData = new FormData()
    if (file != "") {
      formData.append("profile", file, "user-" + AuthData.id + ".png")
    }
    formData.append("name", AuthData?.name)
    formData.append("date_of_birth", formatDate(AuthData?.date_of_birth))

    UserDetailsUpdateApi(formData).then(function (response) {
      if (response.data.status == true) {
        setAuthData(response.data.data)
        MyProfileApi()
        Store.addNotification({
          message: response.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
      else {
        Store.addNotification({
          message: response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
        return false;
      }
    })
      .catch(function (error) {
        console.log(error);
      })
  }
  return (
    <>
      {isDataLoader && (
        <DataLoader />
      )}

      <Helmet>
        <meta name="description" content={"My Profile On Hukup Australia"} />
      </Helmet>

      <div className="container-fluid p-0 main_section">
        <div className="background_section_main">
          <img alt='orangeline' src={require("../../../../src/images/orangeline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_orangeline" />
          <img alt='greenline' src={require("../../../../src/images/greenline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_greenline" />
          <img alt='Ellipse' src={require("../../../../src/images/Ellipse.png")} className="footer_ellipse_img" />
          <img alt='footerblackline' src={require("../../../../src/images/footerblackline.png")} className="footer_blackline_img" />
        </div>
        <Header />

        <div className="user_Detailspage_main_section">
          <div className="container ">
            <div className="row mt-4 ">

              <div className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 ">
                <div className="row">
                  <div className="col-12 position-relative">
                    <div className="mx-auto user_Detailspage_user_img_section">
                      {skeletonUserImage ? (
                        <SkeletonTheme >
                          <div>
                            <Skeleton height={400} />
                          </div>
                        </SkeletonTheme>
                      ) :
                        <div className='position-relative'>
                          {/* <div className='position-absolute end-0'>
                            <HiOutlinePencil />
                          </div> */}

                          <div
                            className="editIcon-Profile"
                            onClick={() => fileInputRef.current.click()}
                          >
                            <HiOutlinePencil />
                          </div>

                          {/* Hidden File Input */}
                          <input
                            type="file"
                            accept="image/*"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                          />

                          <img alt='Profile' src={userData.thumbnail_image ? userData.thumbnail_image : AuthData.thumbnail_image} className="user_Detailspage_user_img" />
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-12">
                    {skeletonUserImage ? (
                      <SkeletonTheme >
                        <div className='mt-3 px-3'>
                          <Skeleton height={30} />
                        </div>
                      </SkeletonTheme>
                    ) :
                      <div className="text-center mx-auto  d-flex align-items-center justify-content-between  mt-4" >
                        <h1 className='mr-3 UserDetails_title_name text-break custom-userDetails-title-name d-flex align-items-center w-100 m-0'>
                          <span>{userData.name}</span>
                          {userData.userProfileVerify === 1 && <img alt='Verify' src={verifyIcon} className='user_verify-icon mb-1 mt-1'></img>}
                        </h1>
                        <img alt='Edit' src={editIcon} className='user_edit_details mb-1' onClick={() => navigate("/my-profile-edit")}></img>
                      </div>
                    }
                    {publicGallery?.length ?
                      <div className="d-flex justify-content-between align-items-center mt-3 mx-auto UserDetails_title_Gallery_main_section">
                        <h1 className="UserDetails_title_Gallery mb-0">My Gallery</h1>
                        <h1 className="UserDetails_title_Seeall mb-0" onClick={() => navigate("/gallery")}>See all</h1>
                      </div> :
                      <div className="text-center mt-3">
                        <h1 className="UserDetails_title_Gallery mb-0 cursor_pointer_common" onClick={() => navigate("/gallery")}>Upload Gallery</h1>
                      </div>
                    }

                    <div className={publicGallery.length ? "d-flex mx-auto mt-3 UserDetails_gallery_main_section" : "d-flex justify-content-center mx-auto mt-3 UserDetails_gallery_main_section"}>
                      {publicGallery.map((e) => {
                        return (
                          <div className="UserDetails_gallery_img_section mx-1" onClick={() => navigate("/gallery")}>
                            {(e.type == "image" || (e.is_private && e.url == "")) ?
                              <>
                                <img alt='Gallary' src={e.url} onError={(element) => {
                                  element.target.src = privateImg
                                }} className="UserDetails_gellery_img" />
                                {
                                  (e.is_private && e.url == "") && (
                                    <img alt='Private Gallary' className='imglock' src={privateImglock} />
                                  )
                                }
                              </> :
                              <div className="videoPlay h-100">
                                <video className='UserDetails_gellery_img'>
                                  <source src={e.url} type="video/mp4" />
                                </video>
                                <span className="play_icon_userdetail_silde" >
                                  <FaPlay />
                                </span>
                              </div>
                            }
                          </div>
                        )
                      })}

                      {
                        skeletonUserImage ? " " :

                          <div className="UserDetails_gallery_img_section mx-1 d-flex">
                            <div className="usergallery_seco_section" onClick={() => navigate("/gallery")}>
                              <FaPlus className="fssliders_icon" />
                            </div>
                          </div>
                      }
                    </div>
                  </div>
                </div>
              </div>

              {/* ======= user details section ============= */}
              <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8  pr-0 pl-0 pl-sm-0 pl-md-0 pl-lg-5 pl-xl-5 mt-4 mt-sm-4 mt-md-0 mt-lg-0 mt-xl-0">
                <div className="user_all_Details_secondsection user_view_details_pageseco">
                  {/* <p className="mb-0 text-center email_number_birth_visible">
                    {siteSetting?.siteSetting?.profile_message}
                  </p> */}



                  {skeletonUserImage && (
                    <SkeletonTheme >
                      <div className="mt-2">
                        <h1 className="user_Detailspage_About">Email</h1>
                        <Skeleton />
                      </div>
                    </SkeletonTheme>
                  )}

                  {skeletonUserImage && (
                    <SkeletonTheme >
                      <div className="mt-4">
                        <h1 className="user_Detailspage_About">About</h1>
                        <Skeleton />
                      </div>
                    </SkeletonTheme>
                  )}


                  {(userData.description !== "" && userData.description?.trim() != "" && userData.description) &&
                    <div className="mt-4">
                      <h1 className="user_Detailspage_About">About</h1>
                      <p className="user_Detailspage_about_introduction">{userData.description}</p>
                    </div>
                  }
                  {
                    AuthData.is_couple == 0 ?
                      <NormalUserProfile skeletonUserImage={skeletonUserImage} userData={userData} />
                      : <PartnerProfile skeletonUserImage={skeletonUserImage} userData={userData} />
                  }
                  {
                    userData.i_am_here_to_value?.length ? (
                      <div className="mt-4">
                        <h1 className="user_Detailspage_About">Here To</h1>
                        <div className="d-flex flex-wrap">
                          {
                            userData?.i_am_here_to_value?.map((item) => {
                              return (
                                <div className={"user_Detailspage_Interests_section mr-2 mt-2"}>
                                  <span>{item}</span>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    ) : (
                      <></>
                    )
                  }

                  {skeletonUserImage && (
                    <SkeletonTheme >
                      <div className="mt-4">
                        <h1 className="user_Detailspage_About">Interests</h1>
                        <Skeleton count={3} />
                      </div>
                    </SkeletonTheme>
                  )}
                  {
                    userData.interest?.length ? (
                      <div className="mt-4">
                        <h1 className="user_Detailspage_About">Interests</h1>
                        <div className="d-flex flex-wrap">
                          {
                            userData?.interest?.map((item) => {
                              return (
                                <div className="user_Detailspage_Interests_section mr-2 mt-2">
                                  <img alt="interesr" className="iconintrest" src={item.image} />{" "}
                                  <span>{item.name}</span>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  {AuthData.eligible_for_referral === 1 && AuthData.is_referral_available && (
                    <Row>
                      <div className="text-end mb-2">
                        <button className='verify_email'
                          onClick={(e) => {
                            paymentoutrefrerral()
                          }}
                        >
                          Pay Out
                        </button>
                      </div>
                      <div className='col-lg-8'>
                        <div className="mt-1">
                          <h1 className="user_Detailspage_About">Referral</h1>
                          <p className="user_Detailspage_about_introduction mb-0 text-break">
                            credit: {siteSetting?.paymentSetting?.symbol}{userData.credits}
                          </p>
                        </div>
                        <div className='my-2'>
                          <Link to="/referral-users" className='email_number_birth_visible text-break linkoverright'>
                            {siteSetting?.siteSetting?.referral_slug}
                          </Link>
                        </div>
                        <InputGroup className="mt-3" id="profileinputgroupreferrlcode">
                          <Form.Control
                            placeholder="Referral Code"
                            aria-label="Referral Code"
                            aria-describedby="basic-addon2"
                            value={userData.referral_link}
                          />
                          <InputGroup.Text id="basic-addon2" className='copyreferrllink' onClick={() => copyTorefrrallink(userData.referral_link)}><MdOutlineContentCopy fill='#fff' /></InputGroup.Text>
                          <InputGroup.Text id="basic-addon2" className='sharereferrllink' onClick={() => copyToClipboard(userData.referral_link)}><MdShare fill='#fff' /></InputGroup.Text>
                        </InputGroup>

                      </div>
                      <div className='col-lg-4 d-flex justify-content-lg-end'>
                        <div className="mt-2">
                          <h1 className="user_Detailspage_About">QR Code</h1>
                          <div className='d-flex align-items-center'>
                            <img src={userData?.qrCode ? userData?.qrCode : qrcodeimg} alt="img" className='setqrcodeimgwidth mr-2' />
                            <div>
                              <a className='qrcodeshareoption'
                                onClick={(e) => { e.preventDefault(); download(userData.qrCode); }}>
                                <IoMdDownload fill='#fff' />
                              </a>
                              <div className='qrcodeshareoption mt-4'
                                onClick={() =>
                                  handleButtonClick(userData.qrCode)}>
                                <MdShare fill='#fff' />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row>
                  )}

                </div>
              </div>
            </div>
          </div>
          <FooterMobile />
        </div>
        <Footer />
      </div>
      {/* ------ add mobilenumber and email  ----  */}
      <Modal
        show={emailNumberShowModal}
        onHide={() => closeToModal()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="main_modal_sectione"
      // size="lg"
      >
        <div className="modal_main_section_add_num_email">
          <Modal.Body>
            {loginwith == "email" && (
              <>
                {
                  emailnumberhideshow ?
                    <>
                      <div className="text-right d-flex align-items-center justify-content-between">
                        <h5 className="mb-0 email_num_title">Email</h5>
                        <AiOutlineClose onClick={() => closeToModal()} className="close_email_number" />
                      </div>
                      <div className="my-4 email_verify_main text-center">
                        <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <span className="text-danger">{emailError}</span>
                        <p className="For_account_recovery">For account recovery</p>
                      </div>
                    </>
                    :
                    <div className="text-right d-flex align-items-center justify-content-between">
                      <h5 className="mb-0 email_num_title">{email ? email : "Email"}</h5>
                      <AiOutlineClose onClick={() => closeToModal()} className="close_email_number" />
                    </div>
                }
              </>
            )}

            {loginwith == "mobile" && (
              <>
                {
                  emailnumberhideshow ?
                    <>
                      <div className="text-right d-flex align-items-center justify-content-between">
                        <h5 className="mb-0 email_num_title" >Mobile Number</h5>
                        <AiOutlineClose onClick={() => closeToModal()} className="close_email_number" />
                      </div>
                      <div className="my-4 text-center ">
                        <div className="d-flex Num_verify_main">
                          <Form.Select
                            value={countryId}
                            onChange={(e) => handleCountryId(e)}
                            aria-label="Default select example">
                            {countryData.map((e) => {
                              return (
                                <option value={e.id}>(+{e.country_code})</option>
                              )
                            })}
                          </Form.Select>
                          <Form.Control type="number"
                            value={number}
                            onChange={(e) => handleMobileNumberChange(e)}
                            placeholder="Enter mobile number"
                          />
                        </div>
                        <span className="text-danger">{numberError}</span>
                        <p className="For_account_recovery">For account recovery</p>
                      </div>
                    </>
                    :
                    <div className="text-right d-flex align-items-center justify-content-between">
                      <h5 className="mb-0 email_num_title" >(+{countrcode}){number ? number : "Mobile Number"}</h5>
                      <AiOutlineClose onClick={() => closeToModal()} className="close_email_number" />
                    </div>
                }
              </>
            )}

            {
              showhideotpbtn ?
                <div className="my-4 verify_otp_main_section">
                  <ReactInputVerificationCode autoFocus={true} length={6} onChange={setVerificationCode} value={verificationCode} />

                  <div className="countdown_text text-center mt-3">
                    {seconds > 0 || minutes > 0 ? (
                      <p className="mb-0 ">
                        {minutes < 10 ? `0${minutes}` : minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds} after
                      </p>
                    ) : (
                      <p className="mb-0">Didn't receive code?</p>
                    )}
                  </div>
                  <div className="text-center">
                    <button
                      className='set_otp_name_center mb-0 mt-1'
                      disabled={seconds > 0 || minutes > 0}
                      style={{
                        color: seconds > 0 || minutes > 0 ? "#fe97b1" : "#EF3672",
                        cursor: seconds > 0 || minutes > 0 ? "not-allowed" : "pointer",
                        pointerEvents: seconds > 0 || minutes > 0 ? "none" : "auto",
                      }}
                      onClick={() => sendOtpUserEmailnumber()}
                    >Re-send code</button>
                  </div>
                </div>

                : ""
            }

            <div className="text-center">
              {
                !showhideotpbtn ?
                  <button className="verify_email" onClick={() => sendOtpUserEmailnumber()}>
                    Send Otp
                  </button>
                  :
                  <button className="verify_email" onClick={() => verifyOtpEmailnumber()}>
                    Submit
                  </button>
              }
            </div>

          </Modal.Body>
        </div>
      </Modal>

      <Modal
        show={unableModalShare}
        onHide={handleshowunablehidemodal}
        centered
      >
        <div className="bg-white login_locatione_main_section">
          <div className="text-right login_locatione_close_main">
            <div className="pl-3">
              <p className="mb-0 login_locatione_title">PLEASE CHECK</p>
            </div>
            <div className="pr-2">
              <IoClose onClick={handleshowunablehidemodal} className="cursor_pointer_common login_locatione_close" />
            </div>
          </div>
          <Modal.Body>
            <p className="location_dis">Please check if your browser supports web share. If so, enable web share option from your browser settings</p>
            <div className="text-right">
              <button className="login_locatione_btn" onClick={handleshowunablehidemodal}>Ok</button>
            </div>
          </Modal.Body>
        </div>
      </Modal>

    </>
  )
}

const NormalUserProfile = ({ skeletonUserImage, userData }) => {

  const navigate = useNavigate();

  return (
    <>
      <div className="mt-4 container  ">
        {skeletonUserImage ? (
          <SkeletonTheme >
            <div>
              <Skeleton />
            </div>
          </SkeletonTheme>
        ) :
          <div className="row pb-1 ">
            <div className="px-0 d-flex justify-content-between">
              <h1 className="user_Detailspage_About p-0 text-break">Your Info</h1>
              {userData?.user_info?.length == 0 && (<button className="verify_email" onClick={() => navigate("/my-profile-edit")}>Add Info</button>)}
            </div>
          </div>
        }
        <div className="row ">
          {skeletonUserImage ? (
            <SkeletonTheme >
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pl-0">
                <Skeleton count={7} />
              </div>
            </SkeletonTheme>
          ) :
            <>
              {userData?.user_info?.length != 0 && (
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pl-0 pb-4 pb-lg-0 order-last order-lg-first pr-0 pr-md-3">
                  <UserInfoComponent dataInfo={userData?.user_info} />
                </div>
              )}
            </>
          }
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pr-0 pl-0 order-first order-lg-last">
            <div className="d-flex flex-wrap justify-content-around user_Detailspage_Gender_Age_mainsection py-0 py-md-4">
              <div className="text-center user_Detailspage_Gender_Age_icon_main use_detai_view_single_user my-3 my-md-0">
                <div className="mx-auto user_Detailspage_Gender_Age_icon_section">
                  <img alt='gender' src={GenderMale} className="user_Detailspage_Gender_Favourites2_img" />
                </div>
                <p className="user_Detailspage_Gender_Age_Favourites_title mb-0 mt-4">Gender</p>
                {skeletonUserImage ? (
                  <SkeletonTheme >
                    <div className='px-2'>
                      <Skeleton />
                    </div>
                  </SkeletonTheme>
                ) :
                  <h3 className="user_Detailspage_Gender_Age_Favourites_value mb-0 mt-1">{userData.gender}</h3>
                }
              </div>
              <div className="text-center user_Detailspage_Gender_Age_icon_main use_detai_view_single_user my-3 my-md-0">
                <div className="mx-auto user_Detailspage_Gender_Age_icon_section">
                  <img alt='gender' src={GenderMale} className="user_Detailspage_Gender_Favourites2_img" />
                </div>
                <p className="user_Detailspage_Gender_Age_Favourites_title mb-0 mt-4">Category</p>
                {skeletonUserImage ? (
                  <SkeletonTheme >
                    <div className='px-2'>
                      <Skeleton />
                    </div>
                  </SkeletonTheme>
                ) :
                  <h3 className="user_Detailspage_Gender_Age_Favourites_value mb-0 mt-1">{userData.category_name}</h3>
                }
              </div>
              {
                (userData.date_of_birth || skeletonUserImage) && (
                  <div className="text-center user_Detailspage_Gender_Age_icon_main use_detai_view_single_user my-3 my-md-0">
                    <div className="mx-auto user_Detailspage_Gender_Age_icon_section">
                      <img alt='Calander' src={Calendar} className="user_Detailspage_Calendar_img" />
                    </div>

                    <p className="user_Detailspage_Gender_Age_Favourites_title mb-0 mt-4" >Age</p>
                    {skeletonUserImage ? (
                      <SkeletonTheme >
                        <div className='px-2'>
                          <Skeleton />
                        </div>
                      </SkeletonTheme>
                    ) :
                      <h3 className="user_Detailspage_Gender_Age_Favourites_value mb-0 mt-1">{userData.age}</h3>
                    }
                  </div>
                )
              }
              <div className="text-center user_Detailspage_Gender_Age_icon_main use_detai_view_single_user my-3 my-md-0">
                {/* <div className="mx-auto user_Detailspage_Gender_Age_icon_section">
                  <img alt='Favourite' src={Favourites2} className="user_Detailspage_Gender_Favourites2_img" />
                </div>
                <p className="user_Detailspage_Gender_Age_Favourites_title mb-0 mt-4">Favourites</p>
                {skeletonUserImage ? (
                  <SkeletonTheme >
                    <div className='px-2'>
                      <Skeleton />
                    </div>
                  </SkeletonTheme>
                ) :
                  <h3 className="user_Detailspage_Gender_Age_Favourites_value mb-0 mt-1">{userData.total_favourites}</h3>
                } */}

                <div className="mx-auto user_Detailspage_Gender_Age_icon_section">
                  <HiOutlinePencil size={25} color='white' className='cursor_pointer_common' onClick={() => navigate("/my-profile-edit")} />

                  {/* <img alt='Favourite' src={editIcon} className="user_Detailspage_Gender_Favourites2_img" /> */}
                </div>
                <p className="user_Detailspage_Gender_Age_Favourites_title mb-0 mt-4">Edit</p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const PartnerProfile = ({ skeletonUserImage, userData }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="mt-4 container">


        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pr-0 pl-0">
            <div className="d-flex flex-wrap justify-content-around user_Detailspage_Gender_Age_mainsection py-0 py-md-4">
              <div className="text-center user_Detailspage_Gender_Age_icon_main_new my-3 my-md-0">
                <div className="mx-auto user_Detailspage_Gender_Age_icon_section">
                  <img alt='Gender' src={GenderMale} className="user_Detailspage_Gender_Favourites2_img" />
                </div>
                <p className="user_Detailspage_Gender_Age_Favourites_title mb-0 mt-4">Gender</p>
                {skeletonUserImage ? (
                  <SkeletonTheme >
                    <div className='px-2'>
                      <Skeleton />
                    </div>
                  </SkeletonTheme>
                ) :
                  <h3 className="user_Detailspage_Gender_Age_Favourites_value mb-0 mt-1">{userData.gender}</h3>
                }
              </div>
              <div className="text-center user_Detailspage_Gender_Age_icon_main_new my-3 my-md-0">
                <div className="mx-auto user_Detailspage_Gender_Age_icon_section">
                  <img alt='Gender' src={GenderMale} className="user_Detailspage_Gender_Favourites2_img" />
                </div>
                <p className="user_Detailspage_Gender_Age_Favourites_title mb-0 mt-4">Category</p>
                {skeletonUserImage ? (
                  <SkeletonTheme >
                    <div className='px-2'>
                      <Skeleton />
                    </div>
                  </SkeletonTheme>
                ) :
                  <h3 className="user_Detailspage_Gender_Age_Favourites_value mb-0 mt-1">{userData.category_name}</h3>
                }
              </div>
              {
                (userData.date_of_birth || skeletonUserImage) && (
                  <div className="text-center user_Detailspage_Gender_Age_icon_main_new my-3 my-md-0">
                    <div className="mx-auto user_Detailspage_Gender_Age_icon_section">
                      <img alt='Calander' src={Calendar} className="user_Detailspage_Calendar_img" />
                    </div>

                    <p className="user_Detailspage_Gender_Age_Favourites_title mb-0 mt-4" >Age</p>
                    {skeletonUserImage ? (
                      <SkeletonTheme >
                        <div className='px-2'>
                          <Skeleton />
                        </div>
                      </SkeletonTheme>
                    ) :
                      <h3 className="user_Detailspage_Gender_Age_Favourites_value mb-0 mt-1">{userData.age}</h3>
                    }
                  </div>
                )
              }
              {
                (userData.partner_details?.partner_dob || skeletonUserImage) && (
                  <div className="text-center user_Detailspage_Gender_Age_icon_main_new my-3 my-md-0">
                    <div className="mx-auto user_Detailspage_Gender_Age_icon_section">
                      <img alt='Calander' src={Calendar} className="user_Detailspage_Calendar_img" />
                    </div>

                    <p className="user_Detailspage_Gender_Age_Favourites_title mb-0 mt-4" >Partner Age</p>
                    {skeletonUserImage ? (
                      <SkeletonTheme >
                        <div className='px-2'>
                          <Skeleton />
                        </div>
                      </SkeletonTheme>
                    ) :
                      <h3 className="user_Detailspage_Gender_Age_Favourites_value mb-0 mt-1">{userData.partner_details?.partner_age}</h3>
                    }
                  </div>
                )
              }
              <div className="text-center user_Detailspage_Gender_Age_icon_main_new my-3 my-md-0">
                <div className="mx-auto user_Detailspage_Gender_Age_icon_section">
                  <img alt='Favourite' src={Favourites2} className="user_Detailspage_Gender_Favourites2_img" />
                </div>
                <p className="user_Detailspage_Gender_Age_Favourites_title mb-0 mt-4">Favourites</p>
                {skeletonUserImage ? (
                  <SkeletonTheme >
                    <div className='px-2'>
                      <Skeleton />
                    </div>
                  </SkeletonTheme>
                ) :
                  <h3 className="user_Detailspage_Gender_Age_Favourites_value mb-0 mt-1">{userData.total_favourites}</h3>
                }
              </div>

            </div>
          </div>
        </div>

        {/* ==== table ====== */}
        <div className='row my-3'>
          {/* user info */}
          <div className='col-lg-6 col-12 px-0'>
            {skeletonUserImage ? (
              <SkeletonTheme >
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pl-0">
                  <Skeleton count={7} />
                </div>
              </SkeletonTheme>
            ) :
              <>
                {userData?.user_info?.length != 0 ? (
                  <div className="col-12 pl-0 pb-4 pb-lg-3 pr-0 pr-md-3">
                    <h1 className="user_Detailspage_About p-0 text-break">{userData.is_mf ? "Male & Profile" : "Your's Info"}</h1>
                    <UserInfoComponent dataInfo={userData?.user_info} />
                  </div>
                ) : (
                  <div className="text-center">
                    <button className="verify_email" onClick={() => navigate("/my-profile-edit")}>Add Your info</button>
                  </div>
                )}
              </>
            }
          </div>
          {/* partner info */}
          <div className='col-lg-6 col-md-12 mt-lg-0 mt-2 px-0'>
            {skeletonUserImage ? (
              <SkeletonTheme >
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
                  <Skeleton count={7} />
                </div>
              </SkeletonTheme>
            ) :
              <>
                {userData?.partner_info?.length != 0 ? (
                  <div className="col-12 pl-0 pb-4 pb-lg-3 pr-0 pl-md-3">
                    <h1 className="user_Detailspage_About p-0 text-break">{userData.is_mf ? "Female Info" : "Partner's Info"}</h1>
                    <UserInfoComponent dataInfo={userData?.partner_info} />
                  </div>
                ) : (
                  <div className="text-center">
                    <button className="verify_email" onClick={() => navigate("/my-profile-edit")}>Add Partner info</button>
                  </div>
                )}
              </>
            }
          </div>
        </div>
      </div>
    </>
  )
}

const UserInfoComponent = ({ dataInfo }) => {

  return (
    <>
      {dataInfo?.map((item) => {
        return (
          <>
            <h4 className="user_Detailspage_info my-2">{item.group_name}</h4>
            {
              item?.fields?.map((ele) => {
                return (
                  <div className="add_new_center_imginfo border-bottom">
                    <img alt='Field' className="text-center user_info_img_table" src={ele.image}></img>
                    <div className="d-flex justify-content-between align-items-center user_info_data_table">
                      <span className="user_info_data_table_catag">{ele.label}</span>
                      {/* <span class="text-end text-break">{ele.field_value.split(",").join(", ")}</span> */}
                      <span class="text-end text-break">{ele.field_value.split(",").map(value => {
                        if (isNaN(value)) {
                          return value;
                        }
                        if (value.indexOf('.') == -1) {
                          return value;
                        }
                        return parseFloat(value).toFixed(0)
                      }).join(", ")}</span>
                    </div>
                  </div>
                )
              })
            }
          </>
        )
      })}
    </>
  )
}

export default UserViewPage