import axios from "axios";
import api from "./index";

export function getLocationPromise() {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      if (navigator.permissions && navigator.permissions.query) {
        navigator.permissions.query({ name: "geolocation" }).then(function (result) {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(function (position) {
              var lat = position.coords.latitude.toString()
              var long = position.coords.longitude.toString()
              resolve({ latitude: lat, longitude: long })
            }, function (error) {
              localStorage.removeItem('locatione_allowed')
              console.log("error", error);
              reject(error.message);
            })
          } else if (result.state === "prompt") {
            if (localStorage.getItem("locatione_allowed") == 'yes') {
              navigator.geolocation.getCurrentPosition(function (position) {
                var lat = position.coords.latitude.toString()
                var long = position.coords.longitude.toString()
                resolve({ latitude: lat, longitude: long })
              }, function (error) {
                localStorage.removeItem('locatione_allowed')
                console.log("error", error);
                reject(error.message);
              })
            }
            else {
              localStorage.removeItem('locatione_allowed')
              reject("Permission not granted")
            }
          }
          else {
            localStorage.removeItem('locatione_allowed')
            reject("Permission not granted")
          }
        });
      }
      else {
        navigator.geolocation.getCurrentPosition(function (position) {
          var lat = position.coords.latitude.toString()
          var long = position.coords.longitude.toString()
          resolve({ latitude: lat, longitude: long })
        }, function (error) {
          localStorage.removeItem('locatione_allowed')
          console.log("error", error);
          reject(error.message);
        })
      }
    } else {
      localStorage.removeItem('locatione_allowed')
      reject("your browser doesn't support geolocation API")
    }
  })
}

axios.interceptors.request.use(async function (config) {
  try {
    if (localStorage.getItem("hukup-info")) {
      const tokenData = localStorage.getItem("hukup-info");
      var data = JSON.parse(atob(tokenData));
      config.headers.Authorization = `Bearer ${data?.token}`;
    }
    var data = await getLocationPromise();
    config.headers.latitude = data.latitude;
    config.headers.longitude = data.longitude;
    return config;
  } catch (error) {
    config.headers.locationerror = error;
    return config;
  }
});

const UNAUTHORIZED = 401;
axios.interceptors.response.use(
  response => response,
  error => {
    try {
      if (error.response) {
        if (error.response.status == UNAUTHORIZED) {
          var data = localStorage.getItem("locatione_allowed")
          localStorage.clear();
          localStorage.setItem("locatione_allowed", data);
          window.location.reload()
        }
      }
      return Promise.reject(error);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

// user Login
export const LoginApi = (data) => {
  return axios.post(api.login, data);
};
// user Login claim account
export const LoginApiClaim = (data) => {
  return axios.post(api.loginClaim, data);
};
// === send-login-otp
export const LogInSendOtpApi = (data) => {
  return axios.post(api.loginsendotp, data);
};

// === send-otp-claim-account
export const LogInSendOtpClaimAccountApi = (data) => {
  return axios.post(api.loginsendotpclaimaccount, data);
};

// CountrycodeApi
export const CountrycodeApi = () => {
  return axios.get(api.countrycode);
};

// Update Profile Api
export const UpdateProfileApi = (data) => {
  return axios.put(api.updateProfile, data);
};

// Update Username Check
export const UsernameCheckApiCall = (data) => {
  return axios.post(api.usernameCheck, data);
};

// profile Second PageData Data 
export const ProfileSecondPageDataApi = () => {
  return axios.get(api.profileSecondPageData);
};

// profile Location Data 
export const ProfilPageLocationDataApi = () => {
  return axios.get(api.profileLocationData);
};

// Update Profile second Page Api
export const UpdateProfileSecondPageApi = (data) => {
  return axios.put(api.updateProfileSecondPage, data);
};

// update profile partner page api
export const UpdateProfilePartenerPageApi = (data) => {
  return axios.post(api.updateProfilepartenerPage, data);
};

// Home Page User data Api 
export const HomeUserListApi = (params, cancelToken) => {
  return axios.post(api.homeUserList + '?page=' + params.page, params, {
    cancelToken: cancelToken.token
  });
};

// Add favoritesUser
export const FavoritesUserApi = (data) => {
  return axios.post(api.favoritesUser, data);
};

// Add Like User
export const LikeUserApi = (data) => {
  return axios.post(api.likeUser, data);
};

// Log Out Api 
export const LogOutApi = () => {
  return axios.get(api.logOut);
};

// UserDetalis   
export const UserDataApi = (data) => {
  return axios.get(api.userDetalis + data);
};

export const UserDetailesNoteApi = (data) => {
  return axios.post(api.userDetailesNote, data);
};

// Report User 
export const ReportUser = (data) => {
  return axios.post(api.report, data);
};

//Get Membership Plan 
export const MembershipPlan = () => {
  return axios.get(api.membershipPlan);
};

//Like By User 
export const LikeYou = (params) => {
  return axios.get(api.likeYou + "?page=" + params.page);
};

//Your Matches
export const Matches = (params) => {
  return axios.get(api.matches + "?page=" + params.page);
};

// galleryView 
export const GalleryView = (id, params) => {
  return axios.get(api.galleryView + id, { params: params });
};


// Like - View 
export const LikeView = (params) => {
  return axios.get(api.likeView + "?page=" + params.page);
};

// DropDown List Report
export const DropdownListReport = () => {
  return axios.get(api.dropdownListReport);
};

export const FavoritesYouApi = (params) => {
  return axios.get(api.favoritesYou + "?page=" + params.page);
};

export const FavoritesByYouApi = (params) => {
  return axios.get(api.favoritesByYou + "?page=" + params.page);
};

// Page List 
export const FooterListApi = () => {
  return axios.get(api.footerList);
};

// Page Detail 
export const FooterPageDataApi = (slug) => {
  return axios.get(api.footerPageData + slug);
};

// Faq page get
export const FaqPageDataApi = () => {
  return axios.get(api.faqPageData);
};

// Faq Meta page get
export const FaqMetaPageDataApi = () => {
  return axios.get(api.faqMetaPageData);
};

// vistedByUser data  
export const VistedByUserApi = (params) => {
  return axios.get(api.vistedByUser + "?page=" + params.page);
};

// userVisitor data  
export const UserVisitor = (params) => {
  return axios.get(api.userVisitor + "?page=" + params.page);
};

// Block User List  data  
export const BlockUserList = () => {
  return axios.get(api.blockUserList);
};

// Block And Unblock User   
export const BlockAndUnblockUser = (id) => {
  return axios.post(api.blockAndUnblockUser, id);
};

// User Public Gallary 
export const UserPublicGallery = (params) => {
  return axios.get(api.userPublicGallery, { params: params });
};

// user all gallery UserAllGallery
export const UserAllGallery = () => {
  return axios.get(api.userAllGalleryapi);
};

// User Private Gallary 
export const UserPrivateGallery = (params) => {
  return axios.get(api.userPrivateGallery, { params: params });
};

// OtherUser Public Gallary 
export const OtherUserPublicGallery = (username, params) => {
  return axios.get(api.otheruserPublicGallery + username, { params: params });
};

// OtherUser Private Gallary 
export const OtherUserPrivateGallery = (username, params) => {
  return axios.get(api.otheruserPrivateGallery + username, { params: params });
};

//  notificationSetting
export const NotificationSetting = (data) => {
  return axios.post(api.notificationSetting, data);
};

//  notificationSetting
export const NotificationView = () => {
  return axios.get(api.notificationView);
};

//  PrivacySetting
export const PrivacySetting = (data) => {
  return axios.post(api.privacySetting, data);
};

//  PrivacyView
export const PrivacyView = () => {
  return axios.get(api.privacyView);
};

//  Report Problem List 
export const ReportProblemListSetting = () => {
  return axios.get(api.reportProblemListSetting);
};

export const ReportSetting = (data) => {
  return axios.post(api.reportSetting, data);
};

// Delete Profile 
export const DeleteProfile = () => {
  return axios.delete(api.deleteProfile,);
};

//  send-delete-otp 
export const SendDeleteOtp = (data) => {
  return axios.post(api.senddeleteotp, data);
};

//  verify-delete-otp
export const VerifyDeleteOtp = (data) => {
  return axios.put(api.verifydeleteotp, data);
};

export const GoingForDateApi = (data) => {
  return axios.post(api.going_for_date, data);
};

export const AcceptRejectApi = (data) => {
  return axios.post(api.accept_reject, data);
};

export const AcceptRejectGalleryApi = (data) => {
  return axios.put(api.accept_reject_gallery_access, data);
};

export const PreMessageAddApi = (data) => {
  return axios.post(api.pre_message_add, data);
};
export const PreMessageViewApi = () => {
  return axios.get(api.pre_message_view);
};
export const PreMessageDeleteApi = (id) => {
  return axios.delete(api.pre_message_delete + "/" + id);
};
export const PreMessageEditApi = (id, data) => {
  return axios.put(api.pre_message_edit + "/" + id, data);
};

export const giftListApi = () => {
  return axios.get(api.gift_list);
};

export const ChatDeleteApi = (data) => {
  return axios.delete(api.chatdelete, {
    data
  });
};

// Payment history 
export const UserPaymentHistory = () => {
  return axios.get(api.paymentHistory);
};

export const paymentHistoryDetailsApi = (params) => {
  return axios.get(api.payment_history_details + params);
};

// home page Filter
export const HomePageFilter = () => {
  return axios.get(api.homepageFilter);
};

//myprofile page 
export const MyProfileData = () => {
  return axios.get(api.myProfile);
}

export const HereToUpdateApi = (data) => {
  return axios.put(api.makeanewfriend, data);
}

// stripe payment create-checkout-session
export const PaymentCreateSession = (data) => {
  return axios.post(api.paymentCreateSession, data);
};

// User update details 
export const UserDetailsUpdateApi = (data) => {
  return axios.put(api.userDetalisUpdate, data);
};
// user interests update
export const UserInterestsUpadteApi = (data) => {
  return axios.put(api.userInterestsUpadte, data);
};

export const addUserGalleryApi = (data) => {
  return axios.post(api.addUserGallery, data);
};

//myProfileEdit filter data 
export const ProfileEditFilter = () => {
  return axios.get(api.profileFilterData);
}
export const ProfileEditFilterPartner = () => {
  return axios.get(api.profileFilterPartnerData);
}
export const ProfileUserInfoFilterPost = (data) => {
  return axios.put(api.profileFilterUserInfoDatapost, data);
}

export const ProfilePartnerFilterPost = (data) => {
  return axios.put(api.profileFilterPartnerDatapost, data);
}

// all type file upload api
export const AllTypeFileUploadApi = (data) => {
  return axios.post(api.image_uploads_url, data);
}

// Gallery image delete api
export const GallaryImageDeleteApi = (data) => {
  return axios.post(api.galleryDataDelete, data);
};

// Gallery image set as a profile  api
export const GallaryImageSetProfileApi = (data) => {
  return axios.post(api.gallerysetprofile, data);
};

// Login Page List 
export const LoginPageLinksApi = () => {
  return axios.get(api.login_page_links);
};

// Referral Page List 
export const ReferralPageLinksApi = () => {
  return axios.get(api.referral_page_links);
};

// Send otp api
export const SendOtpApi = (data) => {
  return axios.post(api.sendotpapi, data);
};

// verify-email-otp
export const VerifyEmailOtp = (data) => {
  return axios.post(api.verifyemailotp, data);
};

// user update using otp 
export const UserUpdateWithOtp = (data) => {
  return axios.put(api.userupdatewithotp, data);
};


//  Site Setting
export const siteSettingAPI = () => {
  return axios.get(api.siteSetting);
};

//UserDetails page in Gallary access
export const AccessUserPrivateGallary = (data) => {
  return axios.post(api.accessPrivateGallary, data);
};

// editpage user categoryUpdate 
export const UserCategoryUpdate = (data) => {
  return axios.put(api.categoryUpdate, data);
};

// editpage user categoryUpdate 
export const userCouponsApplyApi = (data) => {
  return axios.post(api.userCouponsApply, data);
};

export const freePlanPurchaseApi = (data) => {
  return axios.post(api.freePlanPurchase, data);
};

//  Filter Interest Category
export const filterInterestCategory = () => {
  return axios.get(api.filterInterestCategory);
};

//  Offer Modal 
export const offerModal = () => {
  return axios.get(api.offerModalGetDate);
};

//  Offer Modal 
export const getGeneralAnnouncementAPI = () => {
  return axios.get(api.getGeneralAnnouncement);
};

export const EmailVerifyApi = (data) => {
  return axios.post(api.emailVerify, data);
};

//  email-mobile-update
export const EmailMobileUpdateApi = (data) => {
  return axios.post(api.emailMobileUpdate, data);
};

// email-mobile-otp-verify
export const verifyOtpEmailnumberApi = (data) => {
  return axios.put(api.verifyOtpEmailnumber, data);
};

// referraluserlist
export const Referraluserdatalist = () => {
  return axios.get(api.referraluserlist);
};

//payemt referral list
export const Paymentrefreeallist = () => {
  return axios.get(api.paymetreferrallist);
};

// influencer login
export const InfluencerLoginData = (data) => {
  return axios.post(api.influencerlogin, data);
};

// dashbord withdrawalrequest
export const WithdrawalRequest = () => {
  return axios.post(api.withdrawalrequest);
};

// paymet Encashment list
export const Encashmentlist = () => {
  return axios.get(api.encashmentpaymetlist);
};

// user credit apply
export const UsercreditApply = (data) => {
  return axios.post(api.usercreditapply, data);
};

// credit free paln purchase
export const Creditfreeplan = (data) => {
  return axios.post(api.creditfreeplanpurchase, data);
};

// evnet add 
export const EventCreate = (data) => {
  return axios.post(api.eventCreate, data);
};
// evnet add 
export const EventUpdate = (data) => {
  return axios.post(api.eventUpdate, data);
};
// get all event list
export const GetAllEvent = (data) => {
  return axios.post(api.geteventall, data);
};

export const FavoriteEvent = (eventId) => {
  return axios.post(api.favoriteEvent,{
     event_id: eventId,
  });
};

export const GetEventById = (id) => {
  return axios.get(api.geteventbyid + id);
};

// add comment 
export const AddComment = (data) => {
  return axios.post(api.addComment, data);
};

// get my event list
export const MyEventList = () => {
  return axios.get(api.myeventlist);
};

export const FavoriteEventList = (data) => {
  return axios.post(api.favoriteEventList, data);
};

// get comment list
export const GetCommentList = (data) => {
  return axios.get(api.commentList + data);
};

// add verification
export const AddVerification = (data) => {
  return axios.post(api.addverification, data);
};

// get view verification
export const GetVerification = () => {
  return axios.get(api.viewverification);
};
export const GetEventFilter = (data) => {
  return axios.post(api.eventFilter, data);
};

export const GetEventTags = () => {
  return axios.get(api.getEventTags);
};

export const reportComment = (data) => {
  return axios.post(api.reportComment, data);
};

export const sendDefaultMessage = (data) => {
  return axios.post(api.sendDefaultMessage, data);
};

