import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css"
import { useAuth } from "./middleware/AuthProvider";
import { FooterListApi, MembershipPlan } from "./api/Api";
import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";

import Loginpage from "./Pages/LoginandReg/Loginpage";
import SnackbarProviderMessage from "./components/SnackbarProviderMessage";
import Snackbar from "./components/Snackbar";
import PrivateRoute from "./middleware/PrivateRoute";
import Content from "./Pages/CommonComponet/Content";
import Subscriptionplan from "./Pages/Componet/Subscriptionplan";
import ConfirmedPayment from "./Pages/Componet/ConfirmedPayment";
import PaymentHistory from "./Pages/Componet/PaymentHistory";
import UserDetailspage from "./Pages/Componet/UserDetailspage";
import Profiledetails from "./Pages/LoginandReg/Profiledetails/Profiledetails";
import Visitors from "./Pages/Componet/Visitors";
import Gallery from "./Pages/Componet/Gallery";
import Matched from "./Pages/Componet/Matched";
import Likedyou from "./Pages/Componet/Likedyou";
import Favourites from "./Pages/Componet/Favourites";
import BlockedUser from "./Pages/Componet/BlockedUser";
import Blocked from "./Pages/Componet/Blocked";
import EmailVerificationSuccess from "./Pages/Componet/EmailVerificationSuccess";
import EmailVerificationError from "./Pages/Componet/EmailVerificationError";
import Messages from "./Pages/Componet/Messages/Messages";
import Settingnotifications from "./Pages/Componet/Setting/Settingnotifications";
import Privacy from "./Pages/Componet/Setting/Privacy";
import ReportProblem from "./Pages/Componet/Setting/ReportProblem";
import UserViewPage from "./Pages/Componet/UserViewAndEdit/UserViewPage";
import UserEditPage from "./Pages/Componet/UserViewAndEdit/UserEditPage";
import DynamicPage from "./Pages/Componet/DynamicPage/DynamicPage";
import UserGallery from "./Pages/Componet/UserGallery";
import GeneralAnnouncement from "./Pages/Componet/GeneralAnnouncement";
import Faq from "./Pages/Componet/FAQ/Faq";
import Referraluser from "./Pages/Componet/Referraluser";
import InfluencerLogin from "./Pages/LoginandReg/InfluencerLogin";
import Referraldashbord from "./Pages/Componet/Referraldashbord";
import Verification from "./Pages/Componet/Setting/Verification";
import EventsPage from "./Pages/Componet/EventsPage";
import Profileverification from "./Pages/Componet/Profileverification";
import MyEvent from "./Pages/Componet/MyEvent";
import EventDetail from "./Pages/Componet/EventDetail";
// import Claimaccount from "./Pages/LoginandReg/Claimaccount";

const App = () => {

  const { isAuthenticated, siteSetting, AuthData, setMembershipPlans, setFooterList } = useAuth();
  const [loginModalLocatione, setLoginModalLocatione] = useState(false);
  const [unableModalLocatione, setUnableModalLocatione] = useState(false);

  useEffect(() => {
    localStorage.setItem('firstLoad', 'true');
    MembershipPlanApi();
    footerMenuListAPI();

    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, []);

  useEffect(() => {
    if (siteSetting?.siteSetting?.site_favicon) {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = siteSetting?.siteSetting?.site_favicon;
    }
  }, [siteSetting?.siteSetting?.site_favicon]);

  useEffect(() => {
    if (!isAuthenticated) {
      if (navigator.geolocation) {
        // navigator.permissions
        //   .query({ name: "geolocation" })
        //   .then(function (result) {
        //     // console.log(result);
        //     if (result.state === "granted") {
        //       // console.log(result);
        //     } else if (result.state === "prompt") {
        //       setLoginModalLocatione(true)
        //       console.log(result);
        //     } else if (result.state === "denied") {
        //       //If denied then you have to show instructions to enable location
        //     }

        //   });

        if (navigator.permissions && navigator.permissions.query) {
          navigator.permissions
            .query({ name: "geolocation" })
            .then(function (result) {
              if (result.state == "granted") {
                // navigator.geolocation.getCurrentPosition(function (position) {
                // }, function (error) {
                //   console.log("error", error);
                //   // console.log(("call"));
                // })
              }
              else if (result.state === "prompt") {
                if (localStorage.getItem("locatione_allowed") != 'yes') {
                  setLoginModalLocatione(true)
                }
              } else if (result.state === "denied") {
                //If denied then you have to show instructions to enable location
              }

            });
        }
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    } else {
      if (navigator.geolocation) {
        if (navigator.permissions && navigator.permissions.query) {
          navigator.permissions
            .query({ name: "geolocation" })
            .then(function (result) {
              if (result.state === "granted") {
                setLoginModalLocatione(false)
              } else if (result.state === "prompt") {
                if (localStorage.getItem("locatione_allowed") != 'yes') {
                  setLoginModalLocatione(true)
                }
              } else if (result.state === "denied") {
                //If denied then you have to show instructions to enable location
              }
            });
        }
      }
    }

  }, []);

  const handleshowhidemodal = () => {

    setLoginModalLocatione(false)

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        localStorage.setItem('locatione_allowed', 'yes')
        if (isAuthenticated) {
          window.location.reload();
        }
      }, function (error) {
        localStorage.removeItem('locatione_allowed')
        // alert(error.message)
        setUnableModalLocatione(true)
        // console.log("error", error);
      });
    }
    else {
      alert("your browser doesn't support geolocation API")
    }

  }

  const handleshowunablehidemodal = () => {
    setUnableModalLocatione(false)
  }

  const MembershipPlanApi = () => {
    FooterListApi().then(function (response) {
      setFooterList(response.data.data);
    })
      .catch(function (error) {
        console.log(error);
      });
  };

  const footerMenuListAPI = () => {
    MembershipPlan().then(function (response) {
      setMembershipPlans(response.data.data);
    })
      .catch(function (error) {
        console.log(error);
      });
  };


  return (

    <SnackbarProviderMessage>
      <Snackbar />
      {!isAuthenticated && (
        <Routes>
          <Route path="/" element={<Loginpage firebase={true} />} />
          <Route path="/test-login" element={<Loginpage firebase={false} />} />
          <Route path="/influencer-login" element={<InfluencerLogin />} />
          <Route exact path="/email-verification-success" element={<EmailVerificationSuccess />} />
          <Route exact path="/email-verification-error" element={<EmailVerificationError />} />
          <Route exact path="/home" element={<Navigate to='/' replace />} />
          <Route path="/faq" element={<Faq />} />
          <Route exact path="/:page" element={<DynamicPage />} />
          <Route path="*" element={<Navigate to='/' replace />} />
          {/* <Route exact path="/claim-old-account" element={<Claimaccount firebase={true} />} /> */}
        </Routes>
      )}

      {(isAuthenticated && !AuthData.is_block && AuthData.role == "influencer") && (
          <Routes>
            <Route exact path="/home" element={<PrivateRoute> <Referraldashbord /> </PrivateRoute>} />
            <Route exact path="/referral-users" element={<PrivateRoute> <Referraluser /> </PrivateRoute>} />
            <Route exact path="/paymenthistory" element={<PrivateRoute> <PaymentHistory /> </PrivateRoute>} />
            <Route exact path="/:page" element={<PrivateRoute> <DynamicPage /> </PrivateRoute>} />
            <Route path="*" element={<Navigate to='/home' replace />} />
          </Routes>
        
      )
      }

      {(isAuthenticated && !AuthData.is_block && AuthData.role == "user") && (
        <Routes>
          <Route exact path="/home" element={
            <PrivateRoute>
              <Content />
            </PrivateRoute>
          }
          />

          <Route exact path="/faq" element={<PrivateRoute> <Faq /> </PrivateRoute>} />
          <Route exact path="/subscriptionplan" element={<PrivateRoute> <Subscriptionplan /> </PrivateRoute>} />
          <Route exact path="/confirmedpayment/:id" element={<PrivateRoute> <ConfirmedPayment /> </PrivateRoute>} />
          <Route exact path="/paymenthistory" element={<PrivateRoute> <PaymentHistory /> </PrivateRoute>} />
          <Route exact path="/user/:username" element={<PrivateRoute> <UserDetailspage /> </PrivateRoute>} />
          <Route exact path="/visitors" element={<PrivateRoute> <Visitors /> </PrivateRoute>} />
          <Route exact path="/gallery" element={<PrivateRoute> <Gallery /> </PrivateRoute>} />
          <Route exact path="/usergallery/:username" element={<PrivateRoute> <UserGallery /> </PrivateRoute>} />
          <Route exact path="/matched" element={<PrivateRoute> <Matched /> </PrivateRoute>} />
          <Route exact path="/likedyou" element={<PrivateRoute> <Likedyou /> </PrivateRoute>} />
          <Route exact path="/favourites" element={<PrivateRoute> <Favourites /> </PrivateRoute>} />
          <Route exact path="/profile-edit" element={<Profiledetails />} />
          <Route exact path="/blockeduser" element={<PrivateRoute> <BlockedUser /> </PrivateRoute>} />
          <Route exact path="/messages" element={<PrivateRoute> <Messages /> </PrivateRoute>} />
          <Route exact path="/accountsetting" element={<PrivateRoute> <Settingnotifications /> </PrivateRoute>} />
          <Route exact path="/verification" element={<PrivateRoute> <Verification /> </PrivateRoute>} />
          <Route exact path="/privacy" element={<PrivateRoute> <Privacy /> </PrivateRoute>} />
          <Route exact path="/reportproblem" element={<PrivateRoute> <ReportProblem /> </PrivateRoute>} />
          <Route exact path="/my-profile" element={<PrivateRoute> <UserViewPage /> </PrivateRoute>} />
          <Route exact path="/my-profile-edit" element={<PrivateRoute> <UserEditPage /> </PrivateRoute>} />
          <Route exact path="/events-announcement" element={<PrivateRoute> <EventsPage /> </PrivateRoute>} />
          <Route exact path="/my-events" element={<PrivateRoute> <MyEvent /> </PrivateRoute>} />
          <Route exact path="/event-details/:id" element={<PrivateRoute> <EventDetail /> </PrivateRoute>} />
          <Route exact path="/general-announcement" element={<PrivateRoute> <GeneralAnnouncement /> </PrivateRoute>} />
          <Route exact path="/email-verification-success" element={<EmailVerificationSuccess />} />
          <Route exact path="/email-verification-error" element={<EmailVerificationError />} />
          <Route exact path="/referral-users" element={<PrivateRoute> <Referraluser /> </PrivateRoute>} />
          {/* <Route exact path="/claimaccount" element={<Claimaccount />} /> */}

          <Route exact path="/:page" element={<PrivateRoute> <DynamicPage /> </PrivateRoute>} />
          <Route path="*" element={<Navigate to='/home' replace />} />
        </Routes>
      )}

      {(isAuthenticated && AuthData.is_block) ? (
        <Routes>
          <Route exact path="/email-verification-success" element={<EmailVerificationSuccess />} />
          <Route exact path="/email-verification-error" element={<EmailVerificationError />} />
          <Route path="*" element={<Blocked />} />
        </Routes>
      ) : (<></>)}


     {(isAuthenticated && !AuthData.is_block && AuthData.is_profile_verification_required) ? (
        <Routes>
          <Route exact path="/email-verification-success" element={<EmailVerificationSuccess />} />
          <Route exact path="/email-verification-error" element={<EmailVerificationError />} />
          <Route path="*" element={<Profileverification />} />
        </Routes>
      ) : (<></>)}

      <Modal
        show={loginModalLocatione}
        onHide={handleshowhidemodal}
        centered
      >
        <div className="bg-white login_locatione_main_section">
          <div className="text-right login_locatione_close_main">
            <div className="pl-3">
              <p className="mb-0 login_locatione_title">PLEASE SHARE YOUR LOCATION</p>
            </div>
            <div className="pr-2">
              <IoClose onClick={handleshowhidemodal} className="cursor_pointer_common login_locatione_close" />
            </div>
          </div>
          <Modal.Body>
            <p className="location_dis">We are going to ask you to share your location.</p>
            <p className="location_dis">Hukup Australia require access to your location to show matches nearby.</p>
            <div className="text-right">
              <button className="login_locatione_btn" onClick={handleshowhidemodal}>Ok</button>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* ---  unable to locatioin ----- */}

      <Modal
        show={unableModalLocatione}
        onHide={handleshowunablehidemodal}
        centered
      >
        <div className="bg-white login_locatione_main_section">
          <div className="text-right login_locatione_close_main">
            <div className="pl-3">
              <p className="mb-0 login_locatione_title">PLEASE SHARE YOUR LOCATION</p>
            </div>
            <div className="pr-2">
              <IoClose onClick={handleshowunablehidemodal} className="cursor_pointer_common login_locatione_close" />
            </div>
          </div>
          <Modal.Body>
            <p className="location_dis">We are unable to find your location. Please allow location from system and browser setting for this site</p>
            <div className="text-right">
              <button className="login_locatione_btn" onClick={handleshowunablehidemodal}>Ok</button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </SnackbarProviderMessage>
  );
};

export default App;
