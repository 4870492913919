import React, { useEffect, useRef, useState } from "react";
import Header from "../CommonComponet/Header";
import Footer from "../CommonComponet/Footer";
import heartLike from "../../images/Heartlike.png"
import heartUnLike from "../../images/Heartnotlike.png"
import {
  FavoriteEvent,
  FavoriteEventList,
  GetAllEvent,
  GetCommentList,
  GetEventTags,
  getLocationPromise,
} from "../../api/Api";
import FooterMobile from "../CommonComponet/FooterMobile";
import { Helmet } from "react-helmet";
import {
  Button,
  Col,
  Modal,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { CiCalendar, CiLocationOn } from "react-icons/ci";
import { FaSliders } from "react-icons/fa6";
import Offcanvas from "react-bootstrap/Offcanvas";
import Select from "react-select";
import { VscClose } from "react-icons/vsc";
import "react-input-range/lib/css/index.css";
import { timeAgo } from "../../helper/getCookie";
import { useNavigate } from "react-router-dom";
import { MdInsertComment, MdOutlineOutlinedFlag } from "react-icons/md";
import { useAuth } from "../../middleware/AuthProvider";
import ReportComment from "./ReportComment/ReportComment";

const EventsPage = () => {
  
  const divRef = useRef(null);
  const divRefpvt = useRef(null);
  const [allEventList, setAllEventList] = useState([]);
  const [myEventList, setMyEventList] = useState([]);
  const [cardSkeleton, setCardSkeleton] = useState(true);
  const [cardSkeletonMeUser, setCardSkeletonMeUser] = useState(false);
  const [offshow, setOffshow] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [TagFilter, setTagFilter] = useState([]);
  const [orderBy, setEventOrderBy] = useState({ value: "location", label: "Nearby" });
  const [orderByFavourite, setEventOrderByFavourite] = useState({ value: "location", label: "Nearby" });
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const handleShowoff = () => setOffshow(true);
  const handleCloseoff = () => setOffshow(false);

  const eventCategoryOptions = [
    { value: "Party", label: "Party" },
    { value: "Event", label: "Event" },
    { value: "Cruise", label: "Cruise" },
    { value: "Venue", label: "Venue" },
  ];

  const orderByOptions = [
    { value: "", label: "Select Order Type" },
    { value: "location", label: "Nearby" },
    { value: "date", label: "Event" },
    { value: "lastComment", label: "Recent Reply" },
  ];


  useEffect(() => {
    document.title = "EVENTS and PARTIES On Hukup Australia";
  }, []);

  var AllEventCount = (
    <div className="Favourited_tab_title_name">
      All Events
    </div>
  );

  var MyEventCount = (
    <div className="Favourited_tab_title_name">
      Favourite Events
    </div>
  );
  const [key, setKey] = useState("AllEvents");

  useEffect(() => {
    if(key == "AllEvents") {
      if(!cardSkeleton) {
        handleFilter();
      }
    }
    else {
      handleFilter();
    }
  }, [key]);

  const MyFavoriteEventListApi = (data) => {
    if(!myEventList.length) {
      setCardSkeletonMeUser(true);
    }
    FavoriteEventList(data)
      .then(function (response) {
        setCardSkeletonMeUser(false);
        setMyEventList(response.data.data);
        handleCloseoff();
      })
      .catch(function (error) {
        setCardSkeletonMeUser(false);
        console.log(error);
        handleCloseoff();
      });
  };

  const [filterCount, setFilterCount] = useState(0);
  var count = 0

  useEffect(() => {

    const storedCategory = localStorage.getItem('event-filter-category');
    const storedOrderBy = localStorage.getItem('event-filter-order-by');
    const storedOrderByFavouite = localStorage.getItem('event-filter-order-by-favourite');
    const storedTags = localStorage.getItem('event-filter-tags');
    const longitudeLocal = localStorage.getItem('longitude');
    const latitudeLocal = localStorage.getItem('latitude');
    
    // Parse the arrays from localStorage
    const categoryArray = storedCategory ? JSON.parse(storedCategory) : [];
    const eventOrderBy = storedOrderBy ? JSON.parse(storedOrderBy) : { value: "location", label: "Nearby" };
    const eventOrderByFavouite = storedOrderByFavouite ? JSON.parse(storedOrderByFavouite) : { value: "location", label: "Nearby" };
    const TagFilterArray = storedTags ? JSON.parse(storedTags) : [];

    // Check if arrays are not empty and set state
    if (Array.isArray(categoryArray) && categoryArray.length > 0) {
      setCategoryFilter(categoryArray);
      count++;
    }

    if (Array.isArray(TagFilterArray) && TagFilterArray.length > 0) {
      setTagFilter(TagFilterArray);
      count++;
    }


    count++;
    if (eventOrderBy) {
      setEventOrderBy(eventOrderBy);
    }
    else {
      setEventOrderBy({ value: "location", label: "Nearby" });
    }

    if (eventOrderByFavouite) {
      setEventOrderByFavourite(eventOrderByFavouite);
    }
    else {
      setEventOrderByFavourite({ value: "location", label: "Nearby" });
    }
    
    setLatitude(latitudeLocal)
    setLongitude(longitudeLocal)
    setFilterCount(count)

    let selectedCategories = categoryArray ? categoryArray.map((value) => value.value) : [];
    let selectedOrderBy = eventOrderBy ? eventOrderBy?.value : "location";
    let selectedTags = TagFilterArray ? TagFilterArray.map((value) => value.value) : [];

    // Calculate the filter count properly
    let newFilterCount = 0;

    if (selectedCategories.length > 0) {
      newFilterCount += 1; // Category selected
    }
    if (selectedTags.length > 0) {
      newFilterCount += 1; // Category selected
    }

    if (selectedOrderBy) {
      newFilterCount += 1; // OrderBy selected
    }

    setFilterCount(newFilterCount);
    const data = {
      type: selectedCategories.toString(), // Send selected category value
      tags: selectedTags.toString(),
      orderBy: selectedOrderBy,
      userLatitude: latitudeLocal,
      userLongitude: longitudeLocal
    };
    localStorage.setItem('event-filter-category', JSON.stringify(categoryArray))
    localStorage.setItem('event-filter-order-by', JSON.stringify(eventOrderBy))
    localStorage.setItem('event-filter-order-by-favourite', JSON.stringify(eventOrderByFavouite))
    localStorage.setItem('event-filter-tags', JSON.stringify(TagFilterArray))
    localStorage.setItem('latitude', (latitude))
    localStorage.setItem('longitude', (longitude))
    FilterApi(data)
  }, []);

  const clearalldata = () => {
    localStorage.removeItem('event-filter-category')
    localStorage.removeItem('event-filter-order-by')
    localStorage.removeItem('event-filter-tags')
    localStorage.removeItem('longitude')
    localStorage.removeItem('latitude')
    setCategoryFilter(null)
    setTagFilter(null)
    setEventOrderBy({ value: "location", label: "Nearby" })
    handleCloseoff()
    setFilterCount(0)
    handleFilter()
  }

  const handleFilter = () => {
    let selectedCategories = categoryFilter ? categoryFilter.map((value) => value.value) : [];
    let selectedOrderBy = orderBy ? orderBy.value : "location";
    let selectedOrderByFavourite = orderByFavourite ? orderByFavourite.value : "location";
    let selectedTags = TagFilter ? TagFilter.map((value) => value.value) : [];

    // Calculate the filter count properly
    let newFilterCount = 0;

    if (selectedCategories.length > 0) {
      newFilterCount += 1; // Category selected
    }
    if (selectedTags.length > 0) {
      newFilterCount += 1; // Category selected
    }

    if (selectedOrderBy || selectedOrderByFavourite) {
      newFilterCount += 1; // OrderBy selected
    }

    setFilterCount(newFilterCount);

    const data = {
      type: selectedCategories.toString(), // Send selected category value
      tags: selectedTags.toString(),
      orderBy: selectedOrderBy,
      userLatitude: latitude,
      userLongitude: longitude
    };

    localStorage.setItem('event-filter-category', JSON.stringify(categoryFilter))
    localStorage.setItem('event-filter-order-by', JSON.stringify(orderBy))
    localStorage.setItem('event-filter-order-by-favourite', JSON.stringify(orderByFavourite))
    localStorage.setItem('event-filter-tags', JSON.stringify(TagFilter))
    localStorage.setItem('latitude', (latitude))
    localStorage.setItem('longitude', (longitude));
    if(key == "AllEvents") {
      FilterApi(data);
    }
    else {
      MyFavoriteEventListApi({
        orderBy: selectedOrderByFavourite
      });
    }
  };

  const FilterApi = (data) =>{
    setCardSkeleton(true)
    GetAllEvent(data)
    .then((response) => {
      setCardSkeleton(false);
      setAllEventList(response.data.data)
      handleCloseoff();
    })
    .catch((error) => {
      setCardSkeleton(false);
      handleCloseoff();
      console.error("error", error);
    });
  }

  const [tagData, setTagData] = useState([])
  const GetEventTagsApi = () => {
    GetEventTags().then(function (response) {
      // handle success
      setTagData(response.data.data.map((ele) => ({ ...ele, label: ele.eventTags, value: ele.id })))
    })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  
  useEffect(() => {
    async function getLocation() {
      var data = await getLocationPromise();
      setLatitude(data.latitude);
      setLongitude(data.longitude)
    }
    getLocation()
    GetEventTagsApi()
  }, [])

  const removeFromFavourite = (id) => {
    setMyEventList(myEventList.filter((e) => e.id !== id))
  }

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={"EVENTS and PARTIES On Hukup Australia"}
        />
      </Helmet>

      <div className="container-fluid p-0 main_section">
        <div className="background_section_main">
          <div className="yellow_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="orange_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <img
            alt="Whitedot"
            src={require("../../../src/images/Whitedot.png")}
            className="d-none d-sm-none d-md-none d-lg-block Whitedot_first"
          />
          <img
            alt="Whitedot"
            src={require("../../../src/images/Whitedot.png")}
            className="d-none d-sm-none d-md-none d-lg-block Whitedot_second"
          />
          <img
            alt="orangeline"
            src={require("../../../src/images/orangeline.png")}
            className="d-none d-sm-none d-md-none d-lg-block footer_orangeline"
          />
          <img
            alt="greenline"
            src={require("../../../src/images/greenline.png")}
            className="d-none d-sm-none d-md-none d-lg-block footer_greenline"
          />
          <img
            alt="Ellipse"
            src={require("../../../src/images/Ellipse.png")}
            className="footer_ellipse_img"
          />
          <img
            alt="footerblackline"
            src={require("../../../src/images/footerblackline.png")}
            className="footer_blackline_img"
          />
        </div>
        <Header />
        <div className="fssliders_section" onClick={handleShowoff}>
          {filterCount > 0 ? <div className="home_filter_count_dot_main_section"><span className="home_filter_count">{filterCount}</span></div> : ""}
          <FaSliders className="fssliders_icon" />
        </div>
        <div className="container-fluid content_main px-0">
          <div className="container">
            <div className="row">
              <div className="col-12 Blocked_User_section_contain">
                <div className="row  ">
                  <Tabs
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="my-2 px-1 mb-3"
                  >
                    <Tab
                      eventKey="AllEvents"
                      title={AllEventCount}
                      className="px-0 tab_liked_you"
                    >
                      <div className="col-12 Liked_section_slider mb-3" ref={divRef}>
                        {cardSkeleton && (
                          <SkeletonTheme>
                            <Row className="">
                              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                                (ele) => {
                                  return (
                                    <Col xs={12} md={12} lg={12} xl={12}>
                                      <Skeleton height={200} count={1} />
                                    </Col>
                                  );
                                }
                              )}
                            </Row>
                          </SkeletonTheme>
                        )}
                        <div className="row row-cols-12 row-cols-lg-12 row-cols-md-12 row-cols-xl-13 px-0 px-sm-0 px-md-0 px-lg-0 px-xl-0">
                          {allEventList?.map((item, index) => {
                            return (
                              <ProfileCard
                                item={item}
                                is_event_favorite={false}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </Tab>

                    <Tab eventKey="MyEvents" title={MyEventCount}>
                      <div
                        className="col-12 Liked_section_slider"
                        ref={divRefpvt}
                      >
                        {cardSkeletonMeUser && (
                          <SkeletonTheme>
                            <Row className="">
                              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                                (ele) => {
                                  return (
                                    <Col xs={12} md={12} lg={12} xl={12}>
                                      <Skeleton height={200} count={1} />
                                    </Col>
                                  );
                                }
                              )}
                            </Row>
                          </SkeletonTheme>
                        )}
                        <div className="row row-cols-12 row-cols-lg-12 row-cols-md-12 row-cols-xl-12 px-0 px-sm-0 px-md-0 px-lg-0 px-xl-0">
                          {myEventList?.map((item, index) => {
                            return (
                              <ProfileCard
                                item={item}
                                is_event_favorite={true}
                                removeFromFavourite={removeFromFavourite}
                              />
                            )
                          })}
                          {myEventList?.length === 0 && (
                            <div className="text-center w-100 py-5">
                              <h5>You have not favorited any events</h5>
                            </div>
                          )}
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
          <FooterMobile />
        </div>
        <Footer />
      </div>

      {/* ====== Filter section ======== */}
      <Offcanvas show={offshow} onHide={handleCloseoff} placement="end" className="offcanvas_main">
        <div className="offcanvas_header d-flex justify-content-between align-items-center">
          <h1 className="mb-0 offcanvas_filter_name">Event Filter</h1>
          <VscClose className="offcanvas_close_icon cursor_pointer_common" onClick={handleCloseoff} />
        </div>

        <div className="text-center">
          <button className="save_update_data my-3 mx-1" onClick={clearalldata}>Clear</button>
          <button className="save_update_data my-3 mx-1" onClick={()=>handleFilter()}>Filter</button>
        </div>

        <div className="row px-2 pb-4 ">
        {key == "AllEvents" ? (
          <>
            <div className="mt-3 px-0">
              <div className="mt-3 ml-4 px-0 offcanvas_Interest_selector">
                <h5 className="mb-2 offcanvas_cheakbox_header">Filter</h5>
                <div className="position-relative">
                  <Select
                    options={eventCategoryOptions}
                    placeholder="Select Event Type"
                    value={categoryFilter}
                    onChange={setCategoryFilter}
                    isSearchable={false}
                    isMulti
                    isClearable={false}
                  />
                </div>
                <div className="position-relative mt-2">
                  <Select
                    options={tagData}
                    placeholder="Select Event Tag"
                    value={TagFilter}
                    onChange={setTagFilter}
                    isSearchable={false}
                    isMulti
                    isClearable={false}
                  />
                </div>
              </div>
            </div>

            <div className="mt-5">
              <fieldset className="filter_fieldset_border">
              </fieldset>
            </div>

            <div className="mt-3 px-0">
              <div className="mt-3 ml-4 px-0 offcanvas_Interest_selector">
                <h5 className="offcanvas_cheakbox_header">Order By</h5>
                <div className="position-relative">
                  <Select
                    options={orderByOptions}
                    placeholder="Select Order Type"
                    value={orderBy}
                    onChange={setEventOrderBy}
                    isSearchable={false}
                    isClearable={false}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="mt-3 px-0">
            <div className="mt-3 ml-4 px-0 offcanvas_Interest_selector">
              <h5 className="offcanvas_cheakbox_header">Order By</h5>
              <div className="position-relative">
                <Select
                  options={orderByOptions}
                  placeholder="Select Order Type"
                  value={orderByFavourite}
                  onChange={setEventOrderByFavourite}
                  isSearchable={false}
                  isClearable={false}
                />
              </div>
            </div>
          </div>
        ) }


        </div>
      </Offcanvas>
    </>
  );
};

export default EventsPage;

const ProfileCard = (props) => {

  var navigate = useNavigate()
  const [isfavourite, setIsFavourite] = useState((props?.item?.is_event_favorite == 1 || props.is_event_favorite == true) ? true : false);
  const [commentMsg, setCommentMsg] = useState(false);
  const [commentMessageList, SetCommentMessageList] = useState([]);

  const CommentListGet = () => {
    GetCommentList(props.item.id)
      .then(function (response) {
        SetCommentMessageList(response.data.data.slice(0, 2));
        setCommentMsg(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    setIsFavourite((props?.item?.is_event_favorite == 1 || props.is_event_favorite == true) ? true : false);
  }, [props?.item?.is_event_favorite, props.is_event_favorite])

  const handleFavoriteEvent = () => {
    FavoriteEvent(props.item.id).then(function (response) {
      if(isfavourite && props.removeFromFavourite) {
        props.removeFromFavourite(props.item.id);
      }
      setIsFavourite(!isfavourite);
    })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }

  return (
    <div className="card_main">
      <div className="event_card_section_main_sidebar_favo ">
        {props?.item?.status == "approved" && (
          <div className="card_section_sidebar_center_main">
            <div className="card_section_chiled" onClick={handleFavoriteEvent}>
              {isfavourite == 1 ? (
                <img
                  src={heartLike}
                  alt="Like"
                  className="icon_heart_red_sidebar"

                />
              ) : (
                <img
                  src={heartUnLike}
                  alt="Unlike"
                  className="icon_heart_sidebar"
                />
              )}
            </div>
            <div
              className="card_section_chiled"
              onClick={CommentListGet}
            >
              <MdInsertComment className="card_Chatpage_luSend_sidebar" />
            </div>
          </div>
        )}
      </div>

      <div className="col px-0">
        <div className="linkover event-card-main-div">
          <div className="event_card_section event_card_section_height d-flex w-100"
            onClick={() => {
              navigate(`/event-details/${props?.item?.id}`);
            }}>
            <div
              className="event_card_img_height"
            >
              <img
                src={props?.item?.image}
                className="card_background_img img-width text-black img-fluid event_card_border_radius"
                alt="Profile"
              />
            </div>
            <div className="user_name_width_set ms-3">
              <h4 className="my-0 event_name fw-bold ">{props?.item?.name}</h4>
              <h6 className="my-0  text-break mt-1 "><span className="event_location">{props?.item?.type}</span></h6>

              {props?.item?.type !== "Cruise" && props?.item?.type !== "Venue" && (
                <p className="my-0 event_location mt-1 d-flex align-items-center">
                  <CiCalendar className="mr-1" size={18} />
                  <span className="event_location_color mt-1">
                    {`${new Date(props?.item?.date).toLocaleDateString('en-US', {
                      weekday: 'short'
                    })}, ${new Date(props?.item?.date).getDate()} ${new Date(props?.item?.date).toLocaleDateString('en-US', {
                      month: 'long'
                    })}, ${new Date(`2000-01-01 ${props?.item?.time}`).toLocaleTimeString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true
                    })}`}
                  </span>
                </p>
              )}

              <div className="d-flex w-100">
                {
                  props?.item?.lastCommentTime && (
                    <div className="my-0  text-break-events mt-1 d-flex align-items-center"><MdInsertComment className="mr-1" size={18} /><span className="event_location">{timeAgo(props?.item?.lastCommentTime)}</span></div>
                  )
                }
                <div className="my-0  text-break-events mt-1 d-flex align-items-center" > <CiLocationOn className="mr-1" size={18} /><span className="event_location">{props?.item?.distance?.toFixed(2)}Km</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={commentMsg}
        onHide={() => {
          setCommentMsg(false);
        }}
      >
        <div className="Userdetails_modal_main_section text-center">
          <h4 className="Userdetails_modal_title">Replies</h4>
          <hr />
          <div>
            <div className="preMessageBox">
              {commentMessageList?.length > 0 ? (
                commentMessageList?.map((e, index) => {
                  return <CommentMessageListComponent data={e} key={index} />;
                })
              ) : (
                <div className="text-center d-flex justify-content-center align-items-center h-100">No reply</div>
              )}
            </div>

            <div className="d-flex justify-content-center">
              <Button
                className="Userdetails_modal_btn_Cancel"
                onClick={() => {
                  setCommentMsg(false);
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </Modal>

    </div>
  );
};

const CommentMessageListComponent = ({ data }) => {
  const navigate = useNavigate();
  const { AuthData } = useAuth();
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <div className="row">
        <div className="col-6 my-1 pe-0 text-nowrap comment_user_name">
          <div className="cursor_pointer_common" onClick={() => navigate("/user/" + data?.user_name)}>
            <img src={data?.user_image || "https://www.datingguide.com.au/images-SponsoredAds/2587.jpg"} className="rounded-pill comment-user-image" />
            <span> {data?.fullName}</span>
          </div>
        </div>
        <div className="col-6 my-1 text-break text-end">
        {(() => {
          const commentTime = new Date(data?.created_at)
          const now = new Date()
          const diffMs = now - commentTime
          const diffMins = Math.floor(diffMs / 60000)
          const diffHours = Math.floor(diffMs / 3600000)
          const diffDays = Math.floor(diffMs / 86400000)
          const diffWeeks = Math.floor(diffDays / 7)

          if (diffMins < 1) {
            return 'Just Now'
          } else if (diffMins < 60) {
            return `${diffMins} minutes ago`
          } else if (diffHours < 24) {
            return `${diffHours} hours ago`
          } else if (diffDays < 7) {
            return `${diffDays} days ago`
          } else {
            return `${diffWeeks} weeks ago`
          }
        })()}
        </div>
      </div>
      <div className="row">
        <div className="col-9 my-1 text-break">
          {data?.comment_text}
        </div>
        <div className="col-3 my-1 text-break text-end">
          {AuthData.id !== data.userId && (
            <div className="text-black cursor_pointer_common">
              <MdOutlineOutlinedFlag size={20} onClick={() => setModalShow(true)} />
            </div>
          )}
        </div>
      </div>
      <ReportComment
        show={modalShow}
        onHide={() => setModalShow(false)}
        comment_id={data.id}
      />
    </>
  );
};